<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<DataTable ref="dt" :value="proformas" v-model:selection="selectedProforma" selectionMode="single" @rowSelect="onProformaSelected" dataKey="id" :paginator="true" :rows="20" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[20,35,50]"
							currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} proformas" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Proformas</h5>
							<div id="table-header-inputs-container">
								<span id="table-search-input" class="block mt-2 md:mt-0 p-input-icon-left">
									<i class="pi pi-search" />
									<AutoComplete placeholder="Buscar por cliente..." ref="autocomplete" :dropdown="false" :multiple="false" v-model="filters['global'].value" @complete="onSearchProformas($event)"></AutoComplete>
								</span>
								<span id="table-buttons-container" class="block mt-2 md:mt-0">
									<Button icon="pi pi-plus" class="p-button-rounded p-button-success mr-2" @click="openNew" />
									<!-- <Button icon="pi pi-filter" class="p-button-rounded p-button-secondary mr-2" @click="toggleFilterMenu($event)" />
									<OverlayPanel ref="op2" appendTo="body" :showCloseIcon="false" id="overlay_panel2" style="width: 250px">
										<h5>Buscar por Cliente</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<InputText v-model="this.clientFilter" id="client" type="text" placeholder="Nombre cliente..." style="width: 100%;"/>
											</div>
										</div>
										<h5>Estado de ventas</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option1" style="width: 100%">Guardada</label>
													<RadioButton id="option1" name="option" value="saved" v-model="saleFilter" />
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option2" style="width: 100%">Archivada</label>
													<RadioButton id="option2" name="option" value="archived" v-model="saleFilter" />
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option3" style="width: 100%">Facturada</label>
													<RadioButton id="option3" name="option" value="receipt" v-model="saleFilter" />
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option4" style="width: 100%">Anuladas</label>
													<RadioButton id="option4" name="option" value="nulled" v-model="saleFilter" />
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option4" style="width: 100%">Todas</label>
													<RadioButton id="option4" name="option" value="all" v-model="saleFilter" />
												</div>
											</div>
										</div>
										<h5>Cobranza</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option1" style="width: 100%">Cobrada</label>
													<RadioButton id="option1" name="option" value="collected" v-model="collectionFilter" />
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option2" style="width: 100%">Por cobrar</label>
													<RadioButton id="option2" name="option" value="pending" v-model="collectionFilter" />
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option3" style="width: 100%">Vencidas</label>
													<RadioButton id="option3" name="option" value="expired" v-model="collectionFilter" />
												</div>
											</div>
										</div>
										<h5>Rango de fechas</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0" style="display: block;">
													<label>Inicio</label>
													<Calendar :showIcon="true" v-model="startFilterDate" dateFormat="dd/mm/yy"></Calendar>
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0" style="display: block;">
													<label>Fin</label>
													<Calendar :showIcon="true" v-model="endFilterDate" dateFormat="dd/mm/yy"></Calendar>
												</div>
											</div>
										</div>
										<h5>Cantidad registros</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<InputText v-model="this.amountFilter" id="amount" type="number" placeholder="Cantidad registros..." style="width: 100%;"/>
											</div>
										</div>
										<Button class="p-button-success mr-2" style="width: 100%;  margin-top: 15px;" :loading="this.loadingFilterButton" label="Filtrar" @click="filterSales" />
									</OverlayPanel>
									<Button icon="pi pi-download" class="p-button-rounded p-button-help mr-2" @click="toggleDownloadMenu($event)" />
									<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="false" id="overlay_panel1" style="width: 250px">
										<h5>Tipo de ventas</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option1" style="width: 100%">Solo facturas</label>
													<RadioButton id="option1" name="option" value="receipt" v-model="saleType" />
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option2" style="width: 100%">Solo ventas</label>
													<RadioButton id="option2" name="option" value="sale" v-model="saleType" />
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option3" style="width: 100%">Solo anuladas</label>
													<RadioButton id="option3" name="option" value="nulled" v-model="saleType" />
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option3" style="width: 100%">Todas</label>
													<RadioButton id="option3" name="option" value="all" v-model="saleType" />
												</div>
											</div>
										</div>
										<h5>Rango de fechas</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0" style="display: block;">
													<label>Inicio</label>
													<Calendar :showIcon="true" v-model="initialFilterDate" dateFormat="dd/mm/yy"></Calendar>
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0" style="display: block;">
													<label>Fin</label>
													<Calendar :showIcon="true" v-model="finalFilterDate" dateFormat="dd/mm/yy"></Calendar>
												</div>
											</div>
										</div>
										<Button class="p-button-success mr-2" style="width: 100%;  margin-top: 15px;" :loading="this.loadingDownloadButton" label="Descargar" @click="downloadSales" />
									</OverlayPanel> -->
								</span>
							</div>
							
						</div>
					</template>

                    <Column field="serialNumber" header="Serial" :sortable="true" headerStyle="width:10%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Serie</span>
							{{slotProps.data.serial !== null && slotProps.data.serial !== undefined ? slotProps.data.serial.toString().padStart(9, "0") : slotProps.data.id.toString().padStart(9, "0") }}
						</template>
					</Column>
					<Column field="customer.name" header="Cliente" :sortable="true" headerStyle="width:30%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Cliente</span>
							{{slotProps.data.customer.name}}
						</template>
					</Column>
					<Column field="total" header="Total" :sortable="true" headerStyle="width:20%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Total</span>
							{{"$"+slotProps.data.total.toFixed(2)}}
						</template>
					</Column>
                    <Column field="createdAtFormatted" header="Fecha" :sortable="true" headerStyle="width:25%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							{{slotProps.data.createdAtFormatted}}
						</template>
					</Column>
					<Column field="state" header="Estado" :sortable="true" headerStyle="width:25%; min-width:10rem;">
						<template #body="slotProps">
							<span :class="'status-badge status-' + slotProps.data.stateBadgeColor">{{slotProps.data.state}}</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>

</template>

<script>
// import download from 'downloadjs';
import {FilterMatchMode} from 'primevue/api';
import httpRequest from '../../service/httpRequest';
import Proforma from '../../models/proforma';

export default {
	data() {
		return {
			proformas: [],
            proforma: new Proforma(),
            selectedProforma: null,
			filters: {},
			// saleType: 'all',
			// clientFilter: null,
			// saleFilter: 'all',
			// collectionFilter: null,
			// initialFilterDate: null,
			// finalFilterDate: null,
			// startFilterDate: null,
			// endFilterDate: null,
			// amountFilter: null,
			// loadingDownloadButton: false,
			// loadingFilterButton: false,
		}
	},
	created() {
        this.initFilters();
        this.getProformas();
	},
	mounted() {
    },
	methods: {
		openNew() {
			this.$router.push('form');
		},
        async getProformas(client=null) {
            let response = await httpRequest.getProformas(client);

            if (response.status == 200) {
                let proformas = response.data;
                this.proformas = proformas.map(function(data) { return new Proforma(data)});
            } else { console.log("Error: ", response.status); }
        },

		async onSearchProformas(event) {
			let response = await httpRequest.getProformas(event.query);
            if (response.status == 200) {
                let proformas_ = response.data;
				for (var index in proformas_) {
					if (!this.proformas.some(proforma => (proforma.id == proformas_[index].id))) {
						this.proformas.push(new Proforma(proformas_[index]));
					}
				}
            } else { console.log("Error: ", response.status); }
		},

		// toggleDownloadMenu(event) {
		// 	this.$refs.op1.toggle(event);
		// },

		// toggleFilterMenu(event) {
		// 	this.$refs.op2.toggle(event);
		// },

		// stopSpinAnimation() {
		// 	var element = document.getElementsByClassName('p-autocomplete-loader pi pi-spinner');
		// 	if (element.length > 0 && element[0]) { element[0].remove(); }
		// },

		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },

        // saleIndex(sale) {
        //     var index = -1;
        //     if (sale.id === null) { index = -1; return index; }
        //     for (var i=0; i<this.sales.length; i++) {
        //         if (this.sales[i].id === sale.id) { index = i; break }
        //     }
        //     return index;
        // },

        // addSaleToList(sale) {
        //     let index = this.saleIndex(sale);
        //     if (index > -1) { this.sales[index] = sale }
        //     else { this.sales = [sale, ...this.sales] } 
        // },

        // deleteSaleFromList(sale) {
        //     let index = this.saleIndex(sale);
        //     this.sales.splice(index, 1);
        //     this.sale = null;
        //     if (this.sales.length == 0) { this.sales = [] }
        // },

		onProformaSelected(event) {
			let proforma = event.data;
			this.$router.push('form?id=' + proforma.id);
		},

		// async downloadSales() {
		// 	// Make date verification
		// 	if (this.initialFilterDate !== null && this.finalFilterDate !== null && this.initialFilterDate>this.finalFilterDate) {
		// 		this.$toast.add({severity:'error', summary: 'Fechas incorrectas', detail: 'La fecha inicial no puede ser mayor a la fecha final', life: 3000});
		// 		return;
		// 	}

		// 	let initialDate = this.initialFilterDate !== null ? this.initialFilterDate.toISOString() : null
		// 	let endDate = this.finalFilterDate !== null ? this.finalFilterDate.toISOString() : null
		// 	this.loadingDownloadButton = true;
		// 	const data = await httpRequest.downloadSales(this.saleType, initialDate, endDate);
		// 	download(data, "ventas" + ".xlsx", "application/vnd.ms-excel");
		// 	this.loadingDownloadButton = false;
		// },

		// async filterSales() {
		// 	// Make date verification
		// 	if (this.startFilterDate !== null && this.endFilterDate !== null && this.startFilterDate>this.endFilterDate) {
		// 		this.$toast.add({severity:'error', summary: 'Fechas incorrectas', detail: 'La fecha inicial no puede ser mayor a la fecha final', life: 3000});
		// 		return;
		// 	}

		// 	let start = this.startFilterDate !== null ? this.startFilterDate.toISOString() : null
		// 	let end = this.endFilterDate !== null ? this.endFilterDate.toISOString() : null
		// 	this.loadingFilterButton = true;

		// 	let response = await httpRequest.getSales(this.clientFilter, this.saleFilter, start, end, this.amountFilter, this.collectionFilter);
        //     if (response.status == 200) {
        //         let sales = response.data;
        //         this.sales = sales.map(function(data) { return new Sale(data)});
        //     } else { this.sales = []; }
		// 	this.loadingFilterButton = false;
		// 	this.$refs.op2.toggle(false);
		// },
	}
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';

@media screen and (max-width: 769px) {
	#table-header-inputs-container {
		display: block;
	}
	#table-search-input {
		margin: 15px 0px !important;
	}
}
@media screen and (min-width: 769px) {
	#table-header-inputs-container {
		display: flex;
	}
	#table-search-input {
		margin: 0px 15px;
	}
}

.status-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.status-badge {
    &.status-green {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-red {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-yellow {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.status-orange {
        background: #ffa177;
        color: #da2727;
    }

	&.status-blue {
        background: #B3E5FC;
        color: #23677B;
    }

	&.status-gray {
        background: #d6d8d9;
        color: #464747;
    }
}
</style>
